import React from 'react';

import AboutProject from 'components/AboutProject';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import SEO from 'components/SEO';

const about = (): JSX.Element => {
  return (
    <Layout>
      <SEO title="O projekcie" />
      <AboutProject />
      <Footer />
    </Layout>
  );
};

export default about;