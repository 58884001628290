import { ChevronRight } from '@material-ui/icons';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import { ContentfulProjectNode } from 'models/contentfulModels';

const AboutProject = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      wave: contentfulAsset(title: {eq: "Path 210"}) {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      project: allContentfulProject(limit: 3, sort: {fields: createdAt, order: DESC}) {
        edges {
          node {
            id
            title
            slug
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="about-project">
      <div className="about-project__content">
        {data.project.edges.map((item: ContentfulProjectNode, id: number) => (
          <div key={item.node.id} className="about-project__section">
            <h1>{item.node.title}</h1>
            <Link to={`/projekt/${item.node.slug}`} className="about-project__box">
              <img 
                alt={item.node.title}
                className="about-project__box-img" 
                src={`https:${item.node.image.file.url}`} 
              />
            </Link>
            <Link 
              className={`main-events__link ${item.node.title === `Działania` ? `main-events__link--pinky` : ``}`} 
              to={`/projekt/${item.node.slug}`}
            >
              <div className="main-events__link-icon">
                <ChevronRight fontSize="inherit" />
              </div>
              <p>WIĘCEJ INFORMACJI</p>
            </Link> 
          </div>
        ))}
      </div>
      <Img className="wave wave--bottom" fluid={data.wave.fluid} />
    </div>
  );
};

export default AboutProject;