import { Facebook, Instagram } from '@material-ui/icons';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import { ContentfulDiscussionNode, ContentfulMaterialNode } from 'models/contentfulModels';

const SubMenu = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      logo: contentfulAsset(title: {eq: "MNiSW_logo"}) {
        fixed(width: 300, height: 47) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
      discussions: allContentfulDiscussions(limit: 3) {
        edges {
          node {
            title
            slug
          }
        }
      }
      materials: allContentfulMaterials(filter: {category: {eq: "Infografika"}}, limit: 3) {
        edges {
          node {
            title
            slug
          }
        }
      }
    }
  `);
  return (
    <div className="submenu">
      <div className="submenu__logo">
        <div className="submenu__logo-img">
          <Img fixed={data.logo.fixed} />
          <h1>
            Dofinansowano z programu „Społeczna odpowiedzialność nauki”
            Ministra Nauki i Szkolnictwa Wyższego
          </h1>
        </div>
      </div>
      <ul className="submenu__list">
        <ul>
          <li className="submenu__header">
            <Link to="/dyskusje">DYSKUSJE</Link>
          </li>
          {data.discussions.edges.map((d: ContentfulDiscussionNode) => 
            <li key={d.node.slug}>
              <Link to={`/dyskusje/${d.node.slug}`}>{d.node.title}</Link>
            </li>
          )}
        </ul>
        <ul>
          <li className="submenu__header">
            <Link to="/materiały">MATERIAŁY</Link>
          </li>
          {data.materials.edges.map((m: ContentfulMaterialNode) => 
            <li key={m.node.slug}>
              <Link to={`/materiały/${m.node.slug}`}>{m.node.title}</Link>
            </li>
          )}
        </ul>
        <ul>
          <li className="submenu__header"><Link to="/projekt">O PROJEKCIE</Link></li>
          <li><Link to="/projekt/idea">Idea projektu</Link></li>
          <li><Link to="/projekt/zespół">Kim jesteśmy?</Link></li>
          <li><Link to="/projekt/działania">Jak działamy?</Link></li>
        </ul>
        <ul>
          <li className="submenu__header"><Link to="/kontakt">KONTAKT</Link></li>
          <li>Fundacja Optimum Pareto</li>
          <li>Celna 6/9, Kraków</li>
        </ul>
      </ul>
      <div className="submenu__icons">  
        <a 
          href="https://www.facebook.com/mysljaknaukowiec/" 
          rel="noopener noreferrer" 
          target="_blank"
          className="submenu__icon"
        >
          <Facebook fontSize="inherit" />
        </a>
        <a 
          href="https://www.instagram.com/mysl_jak_naukowiec" 
          rel="noopener noreferrer" 
          target="_blank"
          className="submenu__icon"
        >
          <Instagram fontSize="inherit" />
        </a>
      </div>
    </div>
  );
};

export default SubMenu;