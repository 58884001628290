import React from 'react';

import Newsletter from './Newsletter';
import Submenu from './Submenu';

const Footer = (): JSX.Element => {
  return (
    <div className="footer">
      <Newsletter />
      <Submenu />
    </div>
  );
};

export default Footer;