import { CircularProgress, TextField } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import axios from 'axios';
import React, { useState } from 'react';

import { isValidMail, isValidForm, handleMixedFormChange } from 'components/Headers/DiscussionProposal/formHelper';
import PrivacyAgree from 'components/Privacy';
import { MixedForm, BooleanForm } from 'models/typeAliases';

const Newsletter = (): JSX.Element => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<BooleanForm>({ mail: false, rodo: false });
  const [form, setForm] = useState<MixedForm>({ mail: '', rodo: false });

  const postEmail = (): void => {
    if (!isValidForm(form, error, setError) || !isValidMail(form.mail as string, error, setError)) return;
    setLoading(true);
    axios.put(`${process.env.SWARMCHECK_PV3_API_URL}/subscribers`, {
      'email': form.mail,
      'newsletter': true,
    })
      .then(() => {
        setError({ mail: false, rodo: false });
        setLoading(false);
        setForm({ ...form, mail: 'NEWSLETTER W DRODZE!' });
      })
      .catch(() => {
        setLoading(false);
        setForm({ ...form, mail: 'SPRÓBUJ PONOWNIE!' });
      });
  };

  return (
    <div className="newsletter">
      <div className="newsletter__text">
        <h1>BĄDŹ NA BIEŻĄCO!</h1>
        <p>
          Zapisując się do naszego newslettera, jako pierwszy uzyskasz dostęp
          do nowych treści, wydarzeń i materiałów!
        </p>
      </div>
      <div className="newsletter__mail">
        <TextField
          error={error.mail}
          helperText={error.mail ? 'Proszę podać poprawny adres e-mail' : ''}
          value={form.mail} 
          onChange={handleMixedFormChange(form, setForm, 'mail')}
          label={'Wpisz swój e-mail'}
          fullWidth
        />
        <div className="newsletter__controls">
          <PrivacyAgree rodo={form.rodo as boolean} error={error.rodo} handleRodoChange={handleMixedFormChange(form, setForm, 'rodo')} />
          {/* TODO - replace it with the MUI Button component */}
          <button className="newsletter__button" onClick={(): void => postEmail()}>
            {loading ? 
              <CircularProgress /> : 
              <>
                <div className="newsletter__button-icon">
                  <ChevronRight fontSize="inherit" />
                </div>
                <h1>ZAPISUJĘ SIĘ</h1>
              </>
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;